.sk-circle-fade {
  width: 54px;
  height: 54px;
  position: relative;
}

.sk-circle-fade-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-circle-fade-dot:before {
  content: '';
  display: block;
  width: 16px;
  height: 16px;
  background-color: #FAA300;
  border-radius: 100%;
  animation: sk-circle-fade 1s infinite ease-in-out forwards;
}

.sk-circle-fade-dot:nth-child(1) {
  transform: rotate(40deg);
}

.sk-circle-fade-dot:nth-child(2) {
  transform: rotate(80deg);
}

.sk-circle-fade-dot:nth-child(3) {
  transform: rotate(120deg);
}

.sk-circle-fade-dot:nth-child(4) {
  transform: rotate(160deg);
}

.sk-circle-fade-dot:nth-child(5) {
  transform: rotate(200deg);
}

.sk-circle-fade-dot:nth-child(6) {
  transform: rotate(240deg);
}

.sk-circle-fade-dot:nth-child(7) {
  transform: rotate(280deg);
}

.sk-circle-fade-dot:nth-child(8) {
  transform: rotate(320deg);
}

.sk-circle-fade-dot:nth-child(9) {
  transform: rotate(360deg);
}

.sk-circle-fade-dot:nth-child(1):before {
  animation-delay: -0.9s;
}

.sk-circle-fade-dot:nth-child(2):before {
  animation-delay: -0.8s;
}

.sk-circle-fade-dot:nth-child(3):before {
  animation-delay: -0.7s;
}

.sk-circle-fade-dot:nth-child(4):before {
  animation-delay: -0.6s;
}

.sk-circle-fade-dot:nth-child(5):before {
  animation-delay: -0.5s;
}

.sk-circle-fade-dot:nth-child(6):before {
  animation-delay: -0.4s;
}

.sk-circle-fade-dot:nth-child(7):before {
  animation-delay: -0.3s;
}

.sk-circle-fade-dot:nth-child(8):before {
  animation-delay: -0.2s;
}

.sk-circle-fade-dot:nth-child(9):before {
  animation-delay: -0.1s;
}

@keyframes sk-circle-fade {
  0% {
    opacity: 1;
    transform: scale(1.02);
  }

  11.11% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), #FAA300;
    transform: scale(0.606);
  }

  22.22% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.8), rgba(255, 255, 255, 0.8)), #FAA300;
    transform: scale(0.703);
  }

  33.33% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)), #FAA300;
    transform: scale(0.751);
  }

  44.44% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), #FAA300;
    transform: scale(0.751);
  }

  55.55% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.4)), #FAA300;
    transform: scale(0.8);
  }

  66.66% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3)), #FAA300;
    transform: scale(0.849);
  }

  77.77% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #FAA300;
    transform: scale(0.897);
  }

  88.88% {
    background: linear-gradient(0deg, rgba(255, 255, 255, 0.05), rgba(255, 255, 255, 0.05)), #FAA300;
    transform: scale(0.97);
  }

  100% {
    opacity: 1;
    transform: scale(1.02);
  }
}
