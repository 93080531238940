@tailwind base;

@layer base {

  html,
  body {
    height: 100%;
  }

  body {
    @apply font-open-sans antialiased bg-page-light text-gray-800;
  }

  path,
  circle,
  ellipse,
  line,
  polygon,
  polyline,
  rect {
    stroke-width: inherit;
  }
}

#root {
  width: 100%;
  height: 100%;
}

@tailwind components;
@tailwind utilities;

@import './custom-loader.scss';
